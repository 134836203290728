.hours-container {
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding: 15px;
  font-size: 16px;
  overflow: auto;
}

.hours-content {
  
}