.header-center-container {
    display: flex;
    flex-flow: column;
    padding: 10px;
    flex-grow: 1;
    position: relative;
}

.header-company-container {
    text-align: center;
    flex: 1;
}

.company-name {
    font-size: 75px;
    line-height: 80px;
}

.company-name-mobile {
    font-size: 25px;
    line-height: 28px;
}

.phone-number {
    font-size: 24px;
    line-height: 35px;
}

.phone-number-mobile {
    font-size: 15px;
    line-height: 17px;
}

.nancy-image {
    margin: 0px;
    padding: 0px;
    min-width: 200px;
}

.nancy-image-mobile {
    margin: 0px;
    padding: 0px;
    min-width: 50px;
}

.header-menu-item {
    font-family: "Crimson", serif;
    font-size: 20px;
}

.header-telehealth {
    position: absolute;
    top: 10px;
    left:10px;
}

.header-telehealth-content {
    color: red;
    font-weight: bold;
    text-align: center;
}
