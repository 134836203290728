html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: "Crimson", serif;
}

@font-face {
  font-family: "Crimson";
  src: url("./assets/Crimson.otf") format("opentype");
  }