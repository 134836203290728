#root {
  height:100%;
}

.app {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr 40px;
  width: 100vw;
  height: 100vh;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
}

.app-header {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-flow: row;
  background: rgb(208,240,192);
  background: linear-gradient(180deg, rgba(208,240,192,1) 0%, rgba(208,240,192,1) 35%, rgba(255,255,255,1) 100%);
}

.app-body {
  grid-column: 1;
  grid-row: 2;
  flex: 1;
  overflow: auto;
  background-color: white;
}

.app-footer {
  grid-column: 1;
  grid-row: 3;
  height: 40px;
  background: rgb(208,240,192);
  background: linear-gradient(0deg, rgba(208,240,192,1) 0%, rgba(208,240,192,1) 35%, rgba(255,255,255,1) 100%);
}

.app-mobile {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 40px;
  width: 100vw;
  height: 100vh;
  min-height: 0;
  min-width: 0;
  overflow: hidden;
}
