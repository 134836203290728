.home-container {
  flex: 1 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: center;
  padding: 15px;
  font-size: 16px;
  overflow: auto;
  flex-direction: column;
}

.home-plan {
  text-align: center;
  max-width: 800px;
  padding: 10px;
}

.home-safety {
  text-align: center;
  max-width: 800px;
  padding: 30px 10px 0 10px;
}

.home-privacy {
  text-align: center;
  max-width: 800px;
  padding: 50px 10px 0 10px;
}

.home-site {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-title {
  text-align: center;
  padding-top: 75px;
  padding-right: 30px;
}

.home-title-mobile {
  padding-top: 20px;
  text-align: center;
}

.home-building {
  padding: 10px;
}

.home-building-mobile {
}

.home-map {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.home-google {
  width: 600px;
}

.home-telehealth-mobile {
  align-items: center;
}
